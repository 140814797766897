import React from "react";
import styled from '@emotion/styled'
import Layout from "../components/layout";
import translate from "../utils/translation";
import { MainTitle } from "../components/common/title";
import cloud from '../../static/images/cloud.svg';

const Wrapper = styled.section`
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2f2f2f;
  
  display: flex;
  align-items: center;
  width: 100%;
  background: url(${cloud}) right center no-repeat;
  background-size: 90%;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 150px;
  @media (max-width: 760px) {
    padding: 0 24px;
  }
`;

const Content = styled.p`
  width: 100%;
  margin: 0 0 16px;

  @media (max-width: 760px) {
    width: 100%;
    letter-spacing: 0.8px;
  }
`;

export const SubSectionTitle = styled.h3`
  margin: 0 0 20px;
  padding: 0;
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 1.2;

  @media (max-width: 760px) {
    font-size: 15px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
`;

export default () =>
  <Layout>
    <Wrapper>
      <Container>
        <MainTitle>
          {translate("privacyPolicy")}
        </MainTitle>
        <Content>
          {/* <SubSectionTitle>Sub Heading 1</SubSectionTitle> */}
          {translate("privacyPolicyData.dateProtectionOfficer")}
        </Content>
        <Content>
          {translate("privacyPolicyData.intro1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.def")}</SubSectionTitle>
          {translate("privacyPolicyData.intro2")}
        </Content>
        <Content>
          {translate("privacyPolicyData.intro3")}
        </Content>
        <Content>
          {translate("privacyPolicyData.intro4")}
        </Content>
        <Content>
          {translate("privacyPolicyData.intro5")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.whatInfo.title")}</SubSectionTitle>
          {translate("privacyPolicyData.userData.part1")}
        </Content>
        
        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.afterSubscribe.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.afterSubscribe.part1")}
        </Content>
        
        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.emailIdAndSettings.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.emailIdAndSettings.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.otherInfo.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.otherInfo.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.otherInfo.part2")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.automatcallyCollected.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.automatcallyCollected.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.automatcallyCollected.part2")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.automatcallyCollected.part3")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.automatcallyCollected.part4")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.howDoesNBWUseData.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.howDoesNBWUseData.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.howDoesNBWUseData.part2")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.howDoesNBWUseData.part3")}
        </Content>
        <Content>
          {translate("privacyPolicyData.scope.howDoesNBWUseData.part4")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.scope.doesNBWReviewMyData.title")}</SubSectionTitle>
          {translate("privacyPolicyData.scope.doesNBWReviewMyData.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.access.sharingData.title")}</SubSectionTitle>
          {translate("privacyPolicyData.access.sharingData.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.access.sharingData.part2")}
        </Content>
        <Content>
          {translate("privacyPolicyData.access.sharingData.part3")}
        </Content>
        <Content>
          {translate("privacyPolicyData.access.sharingData.part4")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.access.sellOrSentData.title")}</SubSectionTitle>
          {translate("privacyPolicyData.access.sellOrSentData.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.access.thirdPartApps.title")}</SubSectionTitle>
          {translate("privacyPolicyData.access.thirdPartApps.part1")}
        </Content>

        <Content>
          {translate("privacyPolicyData.access.thirdPartApps.part2")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.access.sendEmails.title")}</SubSectionTitle>
          {translate("privacyPolicyData.access.sendEmails.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.storage.title")}</SubSectionTitle>
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.storage.infoStored.title")}</SubSectionTitle>
          {translate("privacyPolicyData.storage.infoStored.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.storage.secureMyInfo.title")}</SubSectionTitle>
          {translate("privacyPolicyData.storage.secureMyInfo.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.storage.secureMyInfo.part2")}
        </Content>
        <Content>
          {translate("privacyPolicyData.storage.secureMyInfo.part3")}
        </Content>
        <Content>
          {translate("privacyPolicyData.storage.secureMyInfo.part4")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.storage.deleteData.title")}</SubSectionTitle>
          {translate("privacyPolicyData.storage.deleteData.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.storage.retainedData.title")}</SubSectionTitle>
          {translate("privacyPolicyData.storage.retainedData.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.storage.accountClose.title")}</SubSectionTitle>
          {translate("privacyPolicyData.storage.accountClose.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.cookies.title")}</SubSectionTitle>
          {translate("privacyPolicyData.cookies.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.cookies.howToUse.title")}</SubSectionTitle>
          {translate("privacyPolicyData.cookies.howToUse.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.cookies.howToUse.part2")}
        </Content>
        <Content>
          {translate("privacyPolicyData.cookies.howToUse.part3")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.policyChange.title")}</SubSectionTitle>
          {translate("privacyPolicyData.policyChange.part1")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.nameController.title")}</SubSectionTitle>
          {translate("privacyPolicyData.nameController.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.nameController.part2")}
        </Content>

        <Content>
          <SubSectionTitle>{translate("privacyPolicyData.contactUs.title")}</SubSectionTitle>
          {translate("privacyPolicyData.contactUs.part1")}
        </Content>
        <Content>
          {translate("privacyPolicyData.contactUs.part2")}
        </Content>
      </Container>
    </Wrapper>
  </Layout>;